import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Cadastrar = loadable(() => import('@screens/instituicoes/Cadastrar'));

function Index(props) {
  return (
    <Layout {...props}>
      <Cadastrar {...props} />
    </Layout>
  );
}

export default Index;
